import React from "react";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          // className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          // style={{
          //   backgroundColor: "#4065FC",
          // }}
        >
          {/* <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">

                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Nutrra</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#/">About Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Features</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Security</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Download</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">iPhone</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Android</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">More</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">About Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Terms & Condition</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Contact Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Community</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="footer-bottom border-gray-light mt-5 py-3"          
          style={{
            backgroundColor: "#282828",
          }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © Nutrra Inc. All rights reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a className="small-text" href="#/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
