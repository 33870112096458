import React from "react";
import FeatureImg from "../../components/features/FeatureImg";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Contact from "../../components/contact/Contact";

// import VideoPromo from "../../components/promo/VideoPromo";
import Screenshot from "../../components/screenshot/Screenshot";
// import Team from "../../components/team/Team";
// import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroEight from "./HeroEight";

export default function HomeEight() {
  return (
    <Layout>
      <Navbar bgColor classOption="custom-header" />
      <HeroEight />
      {/* <CounterTwo /> */}
      <FeatureImg ImgSource="assets/img/image-10.png" />
      <Screenshot  />
      <Contact  />
      <Footer  />
    </Layout>
  );
}
