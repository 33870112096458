export default function HeroEight() {
  return (
    <>
      <section className="hero-section hero-section-3 ptb-100">
        <div className="container" id="animation-area-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="hero-content section-title text-center text-lg-left my-5 mt-lg-0">
                <h1 className="font-weight-bold">For Travel Nurses</h1>
                <p className="lead">
                  We provide an exclusive and trustworthy platform tailored for nurses, ensuring a secure and verified environment for seamless interaction and access to essential resources.
                </p>
                <div className="action-btn-container mt-4 d-flex justify-content-center justify-content-lg-start">
                  <div className="rounded-box image-wrapper">
                    <img
                      src="assets/img/appstorebtn.png"
                      alt="Team Image 1"
                      className="img-fluid"
                      style={{ maxWidth: '150px' }} // Adjust size as needed
                    />
                  </div>
                  <div className="rounded-box image-wrapper">
                    <img
                      src="assets/img/googleplay.png"
                      alt="Team Image 2"
                      className="img-fluid"
                      style={{ maxWidth: '150px' }} // Adjust size as needed
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end">
              <div className="hero-img position-relative">
                <div className="image-bg-positioning">
                  <img
                    src="assets/img/app-product.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                {/* <div className="animation-item d-none d-md-block d-lg-block">
                  <div className="p-4 w-75 rounded-custom d-flex white-bg hero-animated-card-1">
                    <p className="gr-text-11 mb-0 text-mirage-2">
                      “A better way to manage your sales, team, clients.”
                    </p>
                    <div className="small-card-img ml-3">
                      <img
                        src="assets/img/team-2.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                  </div>
                  <div className="p-4 w-75 rounded-custom d-flex secondary-bg hero-animated-card-2">
                    <div className="small-card-img mr-3 text-white">
                      <img
                        src="assets/img/team-5.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <p className="gr-text-11 mb-0 text-white">
                      “Ensuring the best return on investment for business”
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
